
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import { Venue } from '@/interfaces/models/Venue';
import StackedForm from '@/mixins/StackedForm';
import { venueFilter } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class CopyFoodcardForm extends StackedForm {
  @Prop({ type: Array, required: true }) public venues!: Venue[];

  get items() {
    return [
      {
        name: 'sourceVenue',
        type: InputType.Autocomplete,
        multiple: false,
        label: 'venue.form.sourceVenue',
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        rules: 'required',
      },
      {
        name: 'targetVenue',
        type: InputType.Autocomplete,
        multiple: false,
        label: 'venue.form.targetVenue',
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        rules: 'required',
      },
    ];
  }
}
