
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VenueApiService from '@/api/http/VenueApiService';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import CopyFoodcardForm from '@/components/venue/form/CopyFoodcardForm.vue';

const app = namespace('app');
const venue = namespace('venue');

@Component({
  components: { CopyFoodcardForm, VWrapper },
})
export default class VenueSync extends mixins(StackedForm) {
  @app.State('venues') public items!: Venue[];

  public copyFoodcard() {
    this.validate().then((res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        const api: VenueApiService = new VenueApiService();
        this.$startLoading('venue.copyFoodcard');
        api
          .copyFoodcard({ ...this.$refs.form.getData() })
          .then(() => {
            this.$router.push({ name: 'venue.index' });
            this.$stopLoading('venue.copyFoodcard');
          })
          .catch(() => this.$stopLoading('venue.copyFoodcard'));
      }
    });
  }
}
